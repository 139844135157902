@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,700;1,400&display=swap');


.font-MonoSerit{
    font-family: 'Montserrat', sans-serif;

}
.piHeading{
  color:#0F1141
}
.underLineOffset{
  text-underline-offset: 5px;
  text-decoration-color: black;
}
.backgroundWaterMark{
  background-image: url('./Assets/backgroundImg.png');
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
 
  background-position: center;
}
.loader {
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;



}
.calibri{
  font-family: Calibri, Helvetica, sans-serif;
}
.timesNewRoman{
  font-family: 'Times New Roman', Times, serif;
}
body::-webkit-scrollbar {
  display: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

table {
  border-spacing: 0px;
  /* table-layout: fixed; */


}

table th {
  word-break: break-all;
}

td {
  /* border: 1px solid #666; */
  word-break: break-all;

}

th {
  /* border: 1px solid #666; */
  word-break: break-all;

}

input[type="date"] {
  background-color: #0080ff;
  padding: 15px;
  position: relative;
  color: #ffffff;
  font-size: 18px;
  border: none;
  outline: none;
  border-radius: 5px;
}

::-webkit-calendar-picker-indicator {
  background-color: #ffffff;
  padding: 5px;
  cursor: pointer;
  border-radius: 3px;
}

@media screen and (max-device-width:1024px),
screen and (max-width:1600px) {
  ul.menu.p-4.md\:w-1\/3.bg-gray-200.text-base-content {
    width: 100%;
    background: #d8dde7;
  }
}

@media screen and (max-device-width:789px),
screen and (max-width:1024px) {
  ul.menu.p-4.md\:w-1\/3.bg-gray-200.text-base-content {
    width: 25%;
    background: #d8dde7;
  }
}

.scrollbar-hidden::-webkit-scrollbar {
  display: none;
}

page {
  background: white;
  display: block;
  margin: 2px auto;
  margin-bottom: 0.5cm;

}

page[size="A4"] {
  width: 21cm;
  height: 29.7cm;
}

page[size="A4"][layout="landscape"] {
  width: 29.7cm;
  height: 21cm;
}



@page {
  size: A4;
  margin: 10mm;
}

::-webkit-scrollbar {
  width: 8px;

}

/* Track */
::-webkit-scrollbar-track {
  background: #d1d0d0;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #369bb9;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #2e79be;
}

@media print {
  /* .navigation {
		display: none;
	} */
}

.b_b {
  border: 1px solid black;
}

@media print {

  footer {
    position: fixed;
    bottom: -5px;
  }

  
}
.bg-wholebg{
  background-color: #F3F6F9;
}

.bg-wholebg {
  background-color: #F3F6F9;
}

@page {
  size: 210mm 297mm;

}

.b_b_None {
  border-bottom: none;
}